html {
  scroll-behavior: smooth;
  --scrollbarBG: #CFD8DC;
  --thumbBG: #90A4AE;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  width: 11px;
}
body {
  background-color: #edf2f7;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

a, button {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 200ms;
}

.bg-svg-01 {
  background-image: url("images/backgrounds/bg-konfigurator-beschreibung.svg");
  min-height: calc(100vh - 80px);
}

.bg-svg-02 {
  background-image: url("images/backgrounds/bg-konfigurator-liste.svg");
  min-height: calc(100vh - 80px);
}

.bg-svg-03 {
  background-image: url("images/backgrounds/bg-konfigurator-start.svg");
  min-height: calc(100vh - 80px);
}

.bg-svg-04 {
  background-image: url("images/backgrounds/bg-konfigurator-upload.svg");
  min-height: calc(100vh - 80px);
}
