@font-face {
  font-family: 'Apercu';
  src: url('../fonts/apercu-light.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Apercu';
  src: url('../fonts/apercu-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Apercu';
  src: url('../fonts/apercu-italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Apercu';
  src: url('../fonts/apercu-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Apercu';
  src: url('../fonts/apercu-medium-italic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Apercu';
  src: url('../fonts/apercu-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}
